<template>
<div>
  <creatsuccess :msg="showtype" @recovery="recovery">
    <div style="padding:10px;">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width='110px' style="margin-bottom:80px;" v-loading='loading'>
        <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>基本信息</span>
            </div>
            <div class="content">
              <el-form-item label="活动名称：" ref="editLotteryDeadlineStart" prop="Name">
                <el-input v-model='ruleForm.Name' :disabled='currentEditType==3' placeholder="最多输入20个字" style="width:458px" maxlength="20"></el-input>
              </el-form-item>
              <el-form-item label="活动时间：" ref="editLotteryDeadlineEnd" required>
                <el-form-item prop="StartTime" class="el-form-date-pick">
                  <el-date-picker :disabled='currentEditType==3||currentEditStatus!=1' placeholder="开始时间" v-model='ruleForm.StartTime' format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" :picker-options='maxOptions'  type="datetime" >
                  </el-date-picker>
                </el-form-item>
                <span style='margin:0 5px;'>~</span>
                <el-form-item prop="EndTime" class="el-form-date-pick">
                  <el-date-picker :disabled='currentEditType==3' v-model='ruleForm.EndTime'  placeholder="结束时间" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" :picker-options='minOptions' type="datetime" default-time="23:59:59">
                  </el-date-picker>
                </el-form-item>
              </el-form-item>

              
              <el-form-item label="活动说明：" prop="">
                <el-input :disabled='currentEditStatus==3' v-model='ruleForm.ActivityDescription' type='textarea' :rows="5" style="width: 500px;" :placeholder="defaultContent" maxlength="500"></el-input>
              </el-form-item>

              <el-form-item label="转盘样式：">
                <el-radio-group v-model="ruleForm.WheelSurfStyleType" class="flex margin-top-10" :disabled="ruleForm.Id>0">
                  <div>
                    <el-radio :label="0">圆形转盘</el-radio>
                  </div>
                  <div class="margin-left-20">
                    <el-radio :label="1">盲盒抽奖</el-radio>
                  </div>
                </el-radio-group>
                <div class="font-14" style="color:#999999FF">活动保存成功后，不可修改转盘样式</div>
              </el-form-item>
            </div>
          </el-card>

          <el-card style="margin-top:10px;">
            <div slot="header" class="clearfix">
              <span>抽奖规则</span>
            </div>
            <el-form-item label="免费抽奖：" prop="EveryDayFreePrizeDrawCount">
              <div class="flexRow">
                <el-checkbox v-model="ruleForm.IsOpenFreePrizeDraw" style="margin-right:10px" :disabled="currentEditType==3"></el-checkbox>
                <span>每人每天可免费抽奖</span>
                <el-input v-model="ruleForm.EveryDayFreePrizeDrawCount" style="width:100px;margin:0px 10px"
                  :disabled="!ruleForm.IsOpenFreePrizeDraw||currentEditType==3"
                  oninput="value=((value.replace(/[^0-9.]/g,'')).replace(/([0-9]+\.[0-9]{2})[0-9]*/,'$1'))"></el-input>
                <span>次</span>
              </div>
              <div style='color:#999;font-size:14px;line-height:2'>
                设置免费抽奖次数，客户进入抽奖页面即可使用
              </div>
            </el-form-item>
            <el-form-item label="积分抽奖：" prop="PointPrizeDrawUsePointCount">
              <div class="flexRow">
                <el-checkbox v-model="ruleForm.IsOpenPointPrizeDraw" style="margin-right:10px" :disabled="currentEditType==3" ></el-checkbox>
                <span>消耗</span>
                <el-input v-model="ruleForm.PointPrizeDrawUsePointCount" style="width:100px;margin:0px 10px"
                 :disabled="!ruleForm.IsOpenPointPrizeDraw||currentEditType==3"
                  oninput="value=((value.replace(/[^0-9.]/g,'')).replace(/([0-9]+\.[0-9]{2})[0-9]*/,'$1'))"></el-input>
                <span>积分，可额外抽奖1次</span>
              </div>
              <div style='color:#999;font-size:14px;line-height:2'>
                抽奖次数不足时，客户可消耗积分进行抽奖
              </div>
            </el-form-item>
            <el-form-item label=" " prop="EveryDayPointPrizeDrawCount" v-if="ruleForm.IsOpenPointPrizeDraw">
              <div style="color:#606266;">
                <span>每人每天最多可使用积分抽奖</span>
                <el-input v-model="ruleForm.EveryDayPointPrizeDrawCount" style="width:100px;margin:0px 10px"
                 :disabled="!ruleForm.IsOpenPointPrizeDraw||currentEditType==3"
                  oninput="value=((value.replace(/[^0-9.]/g,'')).replace(/([0-9]+\.[0-9]{2})[0-9]*/,'$1'))"></el-input>
                <span>次</span>
              </div>
            </el-form-item>
            <el-form-item label="付费抽奖：">
              <div class="flex flex-align-center font-14" style="margin-bottom:30px" v-for="(v,i) in ruleForm.PayConfigList" :key="i">
                <el-checkbox v-model="v.check" :disabled="currentEditStatus>2"> <span></span> </el-checkbox>
                <div>付费</div>
                <el-form-item label-width="0px" :prop="'PayConfigList.'+i+'.PayMoney'" :rules="rules.PayMoney">
                  <el-input style="width:100px;margin:0px 10px" v-model="v.PayMoney" :disabled="!v.check||currentEditStatus>2"
                    oninput="value=((value.replace(/[^0-9.]/g,'')).replace(/([0-9]+\.[0-9]{2})[0-9]*/,'$1'))"></el-input>
                  <span>元，增加</span>
                </el-form-item>
                <el-form-item label-width="0px" :prop="'PayConfigList.'+i+'.PrizeDrawCount'" :rules="rules.PrizeDrawCount">
                  <el-input style="width:100px;margin:0px 10px" v-model="v.PrizeDrawCount" :disabled="!v.check||currentEditStatus>2"
                    onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
                    onafterpaste="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"></el-input>
                  <span>次抽奖机会</span>
                </el-form-item>
              </div>
            </el-form-item>
            <el-form-item label=" ">
              <div class="blur-border">
                提示：转盘抽奖搭配支付有礼活动，客户购买活动内指定商品，也可额外获得抽奖机会
                <el-button type="text" @click="$router.push({path:'/market/payGifts/editPayGiftsDetail',query:{
                  Id:0,
                  type:2,
                  status:''
                }})" style="margin:0px;padding:0px">前往创建支付有礼活动</el-button>
              </div>
            </el-form-item>
          </el-card>

          <el-card style="margin-top:10px;">
            <div slot="header" class="clearfix">
              <span>奖项设置</span>
            </div>
            
            <el-button type="text" @click="handleAddPrize({})" class="is-error-button"
             v-if="((ruleForm.WheelSurfStyleType==0&&ruleForm.PrizeList.length<6)||(ruleForm.WheelSurfStyleType==1&&ruleForm.PrizeList.length<29))&&currentEditType!=3">添加奖项</el-button>
            <div style='color:#999;font-size:14px;line-height:20px;'>
              <span> 1.最多添加{{ruleForm.WheelSurfStyleType==0?6:29}}个奖项</span>
              <br>
              <span> 2.所有奖项，中奖概率之和<=100%;未中奖概率=100%-中奖概率</span>
              <br>
              <span> 3.若奖品不足，即使是100%中奖概率，也会出现未中奖的情况哦，请保持奖品数量充足</span>
            </div>
            <!-- 赠品列表 -->
            <el-table :data="ruleForm.PrizeList" style="width:1000px;margin-top:15px" v-if='ruleForm.PrizeList.length'>
              <el-table-column prop="PrizeType" label="奖品类型">
                <template slot-scope="scope">
                  <div v-if='scope.row.PrizeType==1'>
                    <span>积分</span>
                  </div>
                  <div v-if='scope.row.PrizeType==2'>
                    <span>优惠券</span>
                    <br>
                    <span style='color:#F56C6C;'>{{scope.row.Coupon[0].Delstatus?'已删除':(scope.row.Coupon[0].IsFailure?'已失效':'')}}</span>
                  </div>
                  <div v-if='scope.row.PrizeType==3'>
                    <span>赠品</span>
                    <br>
                    <span style='color:#F56C6C;'>{{scope.row.Product[0].Delstatus?'已删除':(!scope.row.Product[0].IsOpen?'已下架':(scope.row.Product[0].Stock==0?'已售罄':''))}}</span>
                  </div>
                  <div v-if='scope.row.PrizeType==7'>
                    <span>现金红包</span>
                  </div>
                  <div v-if='scope.row.PrizeType==8'>
                    <span>下单折扣</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="PrizeName" width="370" label="奖品名称">
                <template slot-scope="scope">
                  <div class="dialog-name-content">
                    <pre style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;">{{scope.row.PrizeName}}</pre>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="PrizeImgUri" label="图片">
                <template slot-scope="scope">
                    <img style='width:64px;height:64px;'  :src="imgUrl+scope.row.PrizeImgUri" />
                </template>
              </el-table-column>
              <el-table-column prop="AllCount" label="发放总量"></el-table-column>
              <el-table-column prop="NotSendCount" label="剩余"></el-table-column>
              <el-table-column prop="PrizeOdds" label="中奖概率">
                <template slot-scope="scope">
                  {{scope.row.PrizeOdds}}%
                </template>
              </el-table-column>
              <el-table-column label="操作" >
                <template  slot-scope="scope">
                  <el-button @click="handleAddPrize(scope.row,scope.$index)" type="text" >编辑</el-button>
                  <el-button style="color:#F56C6C;"  @click="handleDeletePrize(scope.$index)"  v-if='currentEditStatus==1' type="text">删除</el-button>
                </template>
              </el-table-column>
            </el-table>

            <el-form-item label="兑奖设置：" style="margin-top:20px" prop="EmployeeQRCodeImgUrl" 
              v-if="ruleForm.PrizeList.some(v=>v.PrizeType==7||v.PrizeType==8)">
              <div class = "grayfont">客户抽中现金红包或下单折扣后，联系员工兑换奖项
                <el-popover placement="bottom" trigger="hover">
                  <img :src="imgUrl+'/image/editLotteryDetail-popover.png'" style="width:200px">
                  <el-button slot="reference" type="text" style="margin-left:15px">查看示例</el-button>
                </el-popover>
              </div>
              <div class = "flexRow" style="align-items:flex-start">
                <el-upload :action="imgApi" :show-file-list="false" :on-success="handleAvatarSuccess2"  accept="image/*"
                :before-upload="beforeAvatarUpload" list-type="picture-card">
                  <img v-if="ruleForm.EmployeeQRCodeImgUrl" :src="imgUrl+ruleForm.EmployeeQRCodeImgUrl" style="width:148px;height:148px">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <div class = "flexCol" style="margin-left:10px;height: 148px;">
                  <div>
                    <div class = "grayfont">建议图片宽高比1:1；</div>
                    <div class = "grayfont">大小请控制在1M以内</div>
                  </div>
                  <el-button type="text" style="margin:0px;padding:0px" @click="ruleForm.EmployeeQRCodeImgUrl=''">清空图片</el-button>
                </div>
              </div>
            </el-form-item>
          </el-card>

          <el-card style="margin-top:10px;">
            <div slot="header" class="clearfix">
              <span>未中奖设置</span>
            </div>

            <el-form-item label="未中奖提示语：">
              <el-input  :disabled='currentEditType==3' v-model='ruleForm.NoWinningTip' placeholder="最多输入20个字" style="width:458px" maxlength="20"></el-input>
            </el-form-item>

            <el-form-item label="图片：" prop="">
              <div  style="float:left">
                <el-upload  :disabled='currentEditType==3' class="avatar-uploader" :action="imgApi" :show-file-list="false" :on-success="handleAvatarSuccess"
                list-type="picture-card" style="display: inline-block;marin-left:10px;">
                    <img v-if="ruleForm.NoWinningImgUrl" :src="imgUrl + ruleForm.NoWinningImgUrl" class="upload-avatar" />
                    <i v-else class="el-icon-plus"></i>
                </el-upload>
                <br>
              </div>
              <div style="color:#999;font-size:14px;float:left;margin-left:10px;">
                  图片请上传
                  <br>1.建议尺寸：长100px;宽100px;
                  <br>2.透明背景图
                  <br>3.大小请控制在1M以内
              </div>

            </el-form-item>
          </el-card>

          <el-card style="margin-top:10px;">
            <div slot="header" class="clearfix">
              <span>活动页面设置</span>
            </div>

            <el-form-item label="抽奖背景图：" prop="">
              <div  style="float:left">
                <el-upload  :disabled='currentEditType==3' class="avatar-uploader" :action="imgApi" :show-file-list="false" :on-success="handleAvatarBgSuccess"
                list-type="picture-card" style="display: inline-block;marin-left:10px;">
                    <img v-if="ruleForm.PageBackgroundImgUrl" :src="imgUrl + ruleForm.PageBackgroundImgUrl" class="upload-avatar" />
                    <i v-else class="el-icon-plus"></i>
                </el-upload>
                <br>
              </div>
              <div style="color:#999;font-size:14px;float:left;margin-left:10px;">
                  图片请上传
                  <br>
                  1.建议尺寸：长750px;宽100px;
                  <br>
                  2.透明背景图
                  <br>
                  3.大小请控制在2M以内
              </div>
            </el-form-item>

            <el-form-item label="页面底色：" prop="">
              <el-color-picker  :disabled='currentEditType==3' v-model='ruleForm.MainColor'></el-color-picker>
            </el-form-item>

            <el-form-item label="中奖名单：" prop="">
              <el-radio  :disabled='currentEditType==3' v-model='ruleForm.IsDisplayWinners' :label='true'>显示</el-radio>
              <el-radio  :disabled='currentEditType==3' v-model='ruleForm.IsDisplayWinners' :label='false'>隐藏</el-radio>
            </el-form-item>

          </el-card>
      </el-form>

      <el-dialog title="奖项设置" :visible.sync="editPrizeDia" width="900px" class="dialog">
          <el-form :model="prizeForm" ref="prizeForm" :rules="prizeRules" label-width="120px">
              <el-form-item label="奖品名称：" :inline-message='true' prop="PrizeName">
                <el-input v-model='prizeForm.PrizeName' :disabled='currentEditStatus==3' placeholder="请输入奖品名称，最多10字" style="width:250px" maxlength="10"></el-input>
              </el-form-item>

              <el-form-item label="奖品类型：" :inline-message='true' prop="PrizeType" required>
                <!-- 送积分 -->
                <!--  -->
                <el-radio class='radio-group' :disabled='currentEditStatus!=1&&prizeEditIndex!=null' @change="handleRadioCheck" :label="1" v-model="prizeForm.PrizeType">
                  积分
                  <el-form-item prop="PointNum" ref='editLotteryPointsCount' :inline-message='true' class='el-form-date-pick' :rules="prizeForm.PrizeType==1?prizeRules.PointNum:prizeRules.nocheck">
                    
                    <el-input  :disabled="prizeForm.PrizeType!=1||currentEditStatus!=1&&prizeEditIndex!=null" style="margin: 0 5px;width:200px;" v-model="prizeForm.PointsCount"
                      @change.native="changePointNum" type='number'>
                    </el-input>
                  积分
                  </el-form-item>
                </el-radio>
                <!-- 选优惠券 -->
                <el-form-item class="check-item">
                  <el-radio @change="handleRadioCheck" :disabled='currentEditStatus!=1&&prizeEditIndex!=null' :label="2" v-model="prizeForm.PrizeType">
                    优惠券
                  </el-radio>
                  <template v-if='prizeForm.PrizeType==2&&prizeEditIndex==null||(prizeForm.PrizeType==2&&prizeEditIndex!=null&&currentEditStatus==1)'>
                    <span v-show="!prizeForm.Coupon.length" style="color:#409EFF;cursor: pointer;margin-left:10px;" @click="handlePrizeCoupons">选择优惠券</span>
                    <span v-show="prizeForm.Coupon.length" style="color:#409EFF;cursor: pointer;margin-left:10px;" @click="handlePrizeCoupons">重新选择</span>
                  </template>
                </el-form-item>

                <!-- 优惠券列表 -->
                <el-form-item class="check-item"   style="margin-top:10px" v-if="prizeForm.Coupon&&prizeForm.Coupon.length">
                  <el-table :data="prizeForm.Coupon" style="width: 90%;margin-left:20px;">
                    <el-table-column :key="9" prop="CouponName" label="优惠券">
                      <template slot-scope="scope">
                          <div style='width:150px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap'>{{scope.row.CouponName}}</div>
                      </template>
                    </el-table-column>
                    <el-table-column :key="10" prop="UseTypeValue" label="类型"></el-table-column>
                    <el-table-column :key="12" prop="CouponContent" label="优惠内容"></el-table-column>
                    <el-table-column :key="11" prop="ActivityRangeTypeValue" label="适用商品"></el-table-column>
                    <el-table-column :key="13" prop="LeaveCount"  label="剩余数量"></el-table-column>
                  </el-table>
                </el-form-item>

                <!-- 送赠品 -->
                <el-form-item class="check-item">
                  <el-radio @change="handleRadioCheck" :disabled='currentEditStatus!=1&&prizeEditIndex!=null' :label="3" v-model="prizeForm.PrizeType">
                    赠品
                  </el-radio>
                  <template v-if='prizeForm.PrizeType==3&&(prizeEditIndex==null||(prizeEditIndex!=null&&currentEditStatus==1))'>
                    <span v-if="!prizeForm.Product.length" style="color:#409EFF;cursor: pointer;margin-left:10px;" @click="handlePrizePro">选择赠品</span>
                    <span v-if="prizeForm.Product.length" style="color:#409EFF;cursor: pointer;margin-left:10px;" @click="handlePrizePro">重新选择</span>
                  </template>
                </el-form-item>

                <!-- 赠品列表 -->
                <el-form-item class="check-item"  style="margin-top:10px" v-if="prizeForm.Product&&prizeForm.Product.length">
                  <el-table :data="prizeForm.Product" style="width: 90%;margin-left:20px;">
                    <el-table-column :key="14" prop="ProductName" width="370" label="商品">
                      <template slot-scope="scope">
                        <div class="dialog-pro-name">
                          <img style='width:64px;height:64px;' :src='imgUrl+scope.row.ImgUrl' />
                          <div class="right">
                            <div class="name">
                              <pre style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;">{{scope.row.ProductName}}</pre>
                            </div>
                          </div>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column :key="15" prop="Spec" label="规格">
                      <template slot-scope="scope">
                        <span v-if="scope.row.Spec ==''">
                          默认规格
                        </span>
                        <span v-else>
                          {{scope.row.Spec}}
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column :key="16" prop="Price" label="价格"></el-table-column>
                    <el-table-column :key="17" prop="Stock" label="库存"></el-table-column>
                  </el-table>
                </el-form-item>

                <!-- 现金红包 -->
                <el-form-item style="margin-top:10px">
                  <div class="flexRow">
                    <el-radio @change="handleRadioCheck" :disabled='currentEditStatus!=1&&prizeEditIndex!=null' :label="7"
                    v-model="prizeForm.PrizeType">现金红包</el-radio>
                    <el-form-item class="inline-error" label-width="0px" prop="MaxCashRedEnvelope" v-if="prizeForm.PrizeType==7">
                      <div class = "flexRow">
                        <el-input :disabled='currentEditStatus!=1&&prizeEditIndex!=null' style="margin:0px 10px;width:100px" v-model="prizeForm.MinCashRedEnvelope" placeholder="最小金额"></el-input>
                        <span>元~</span>
                        <el-input :disabled='currentEditStatus!=1&&prizeEditIndex!=null' style="margin:0px 10px;width:100px" v-model="prizeForm.MaxCashRedEnvelope" placeholder="最大金额"></el-input>
                        <span>元</span>
                        <el-popover placement="top" trigger="hover" content="客户抽中现金红包后，需要联系员工兑换奖项">
                          <i slot="reference" class = "el-icon-warning" style="color:#E6A23C;margin-left:10px"></i>
                        </el-popover>
                      </div>
                    </el-form-item>
                  </div>
                </el-form-item>

                <!-- 下单折扣 -->
                <el-form-item style="margin-top:20px">
                  <div class="flexRow">
                    <el-radio @change="handleRadioCheck" :disabled='currentEditStatus!=1&&prizeEditIndex!=null' :label="8"
                    v-model="prizeForm.PrizeType">下单折扣</el-radio>
                    <el-form-item class="inline-error" label-width="0px" prop="MaxOrderDiscount" v-if="prizeForm.PrizeType==8">
                      <div class = "flexRow">
                        <el-input :disabled='currentEditStatus!=1&&prizeEditIndex!=null' style="margin:0px 10px;width:100px" v-model="prizeForm.MinOrderDiscount" placeholder="最小值"></el-input>
                        <span>折~</span>
                        <el-input :disabled='currentEditStatus!=1&&prizeEditIndex!=null' style="margin:0px 10px;width:100px" v-model="prizeForm.MaxOrderDiscount" placeholder="最大值"></el-input>
                        <span>折</span>
                        <el-popover placement="top" trigger="hover" content="客户抽中下单折扣后，需要联系员工兑换奖项">
                          <i slot="reference" class = "el-icon-warning" style="color:#E6A23C;margin-left:10px"></i>
                        </el-popover>
                      </div>
                    </el-form-item>
                  </div>
                </el-form-item>


              </el-form-item>

              <el-form-item label="发放总量：" :inline-message='true' prop="AllCount"  :rules="prizeRules.AllCount">
                <el-input  :disabled='currentEditStatus==3'  style="width:200px;margin-right:5px;" v-model="prizeForm.AllCount"
                  @change.native="changeAllCount" type='number'>
                </el-input>
                份
              </el-form-item>
              <el-form-item label="中奖概率：" :inline-message=true prop="PrizeOdds">
                <el-input @change.native="changePrizeOdds" type='number' :disabled='currentEditStatus==3' style="width:200px;"
              v-model='prizeForm.PrizeOdds'></el-input>
                <span style="margin-left:5px">%</span>
              </el-form-item>

              <el-form-item label="奖项图片：" prop="">
                <div  style="float:left">
                  <el-upload  :disabled='currentEditStatus==3' class="avatar-uploader" :action="imgApi" :show-file-list="false" :on-success="handleAvatarPrizeSuccess"
                  list-type="picture-card" style="display: inline-block;marin-left:10px;">
                      <img v-if="prizeForm.PrizeImgUri" :src="imgUrl + prizeForm.PrizeImgUri" class="upload-avatar" />
                      <i v-else class="el-icon-plus"></i>
                  </el-upload>
                  <br>
                </div>
                <div style="color:#999;font-size:14px;float:left;margin-left:10px;">
                    图片请上传
                    <br>1.建议尺寸：长100px;宽100px;
                    <br>2.透明背景图
                    <br>3.大小请控制在1M以内
                </div>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button class="button" @click="editPrizeDia=false">关闭</el-button>
            <el-button class="button" @click="saveAddPrize('prizeForm')" v-if='currentEditStatus!=3' type="primary">确定</el-button>
          </div>
      </el-dialog>

      <!-- 选择优惠券-->
        <el-dialog title="选择优惠券" :visible.sync="selectCouponShow" v-if="selectCouponShow" width="800px" class="dialog data-dialog">
          <select-coupon :couponCheckedId='couponCheckedId' @getSelectCoupon='getSelectCoupons'></select-coupon>
        </el-dialog>

      <!-- 选择商品-->
        <el-dialog title="选择商品" :visible.sync="selectProShow"  v-if="selectProShow" width="1100px" class="dialog data-dialog">
          <select-produce :proCheckedId='proCheckedId' @getSelection='getSelectPros'></select-produce>
        </el-dialog>

      <div class="footer" >
        <template v-if='currentEditStatus==3'>
          <el-button style="width:240px;" @click="handleCancelSubmit">关闭</el-button>
        </template>
        <template v-else>
          <el-button style="width:240px;" @click="handleCancelSubmit">取消</el-button>
          <el-button style="width:240px;" type="primary" @click="handleSubmit('ruleForm')"  :loading="loading">保存</el-button>
        </template>
      </div>
    </div>
  </creatsuccess>
</div>
</template>
<script>
  import selectProduce from '@/components/SelectSingleProduce/lottery'
  import selectCoupon from '@/components/SelectCouponDialog'
  import config from '@/config/index';
  import apiList from '@/api/other'
  import creatsuccess from "@/components/creatsuccess/creatsuccess.vue"
  export default{
    components:{selectProduce,selectCoupon,creatsuccess},
    data(){
      var checkName = (rule, value, callback) => {
				if (!value || !value.trim()) {
					return callback(new Error('请输入活动名称'));
				}else {
					return callback()
				}
			}
      var checkStartTime = (rule, value, callback) => {
				if (!value) {
					callback(new Error('请完善开始时间'));
				} else {
					return callback()
				}
			}
			var checkEndTime = (rule, value, callback) => {
				if (!value) {
					callback(new Error('请完善结束时间'));
				} else {
					return callback()
				}
      }
      
      var checkPrizeName = (rule, value, callback) => {
				if (!value || !value.trim()) {
					return callback(new Error('请设置奖品名称'));
				}else {
					return callback()
				}
			}
      var checkPointNum = (rule, value, callback) => {
				if (this.prizeForm.PrizeType==1) {
          if(this.prizeForm.PointsCount==''||!this.prizeForm.PointsCount){
					  return callback(new Error('请设置积分'))
          }else if (Number(this.prizeForm.PointsCount) > 9999 || Number(this.prizeForm.PointsCount) < 1) {
						return callback(new Error(`积分请设置在1~9999之间`))
					} else {
						return callback()
					}
				} else {
					return callback()
				}
      }
      var checkAllCount = (rule, value, callback) => {
        if(this.prizeEditIndex!=null&&(this.prizeForm.AllCount < this.prizeForm.SendCount||this.prizeForm.AllCount == this.prizeForm.SendCount)){
          return callback(new Error('总量需大于已发放数量'))
        }
				if (this.prizeForm.AllCount==''||!this.prizeForm.AllCount) {
					return callback(new Error('请设置发放总量'))
				} else {
          if (Number(this.prizeForm.AllCount) > 999999 || Number(this.prizeForm.AllCount) < 1) {
						return callback(new Error(`总量请设置在1~999999之间`))
					} else {
						return callback()
					}
					return callback()
				}
      }
      var checkPrizeOdds= (rule, value, callback) => {
        if (this.prizeForm.PrizeOdds==''||!this.prizeForm.PrizeOdds) {
					return callback(new Error('请设置中奖概率'))
        }else{
          if (Number(this.prizeForm.PrizeOdds) > 100 || Number(this.prizeForm.PrizeOdds) < 0 || Number(this.prizeForm.PrizeOdds) ==0) {
						return callback(new Error(`概率请设置在0%~100%之间，最多2位小数`))
					} else {
						return callback()
					}
        }
      }
      var checkCoupon = (rule, value, callback) => {
				if (!this.prizeForm.Coupon.length&&this.prizeForm.PrizeType==2) {
					return callback(new Error('请选择优惠券'))
				} else {
					return callback()
				}
      }
      var checkProduct = (rule, value, callback) => {
				if (!this.prizeForm.Product.length&&this.prizeForm.PrizeType==3) {
					return callback(new Error('请选择赠品'))
				} else {
					return callback()
				}
      }
      var checkMaxCashRedEnvelope = (rule, value, callback) => {
        let two = /^\d+(\.\d{1,2})?$/
        let min = this.prizeForm.MinCashRedEnvelope
        let max = this.prizeForm.MaxCashRedEnvelope
				if(this.prizeForm.PrizeType==7){
          if(min <0.01 || min >500 || !two.test(min) || max <0.01 || max >500 || !two.test(max)){
            callback(new Error('金额请设置在0.01~500元之间，最多2位小数'))
          }else if(min*100 > max*100){
            callback(new Error('最小金额不可大于最大金额'))
          }else{
            callback()
          }
        }else{
          callback()
        }
      }
      var checkMaxOrderDiscount = (rule, value, callback) => {
        let one = /^\d+(\.\d{1,1})?$/
        let min = this.prizeForm.MinOrderDiscount
        let max = this.prizeForm.MaxOrderDiscount
				if(this.prizeForm.PrizeType==8){
          if(min <1 || min >9.9 || !one.test(min) || max <1 || max >9.9 || !one.test(max)){
            callback(new Error('折扣请设置在1~9.9折之间，最多1位小数'))
          }else if(min*100 > max*100){
            callback(new Error('折扣数值最小值不可大于最大值'))
          }else{
            callback()
          }
        }else{
          callback()
        }
      }
      var checkEveryDayFreePrizeDrawCount = (rule, value, callback) => {
        if(this.ruleForm.IsOpenFreePrizeDraw){
          if(value!=parseInt(value)||value < 1 || value > 99){
            callback(new Error('次数请设置在1~99之间，整数'))
          }else{
            callback()
          }
        }else{
          callback()
        }
      }
      var checkPointPrizeDrawUsePointCount = (rule, value, callback) => {
        if(this.ruleForm.IsOpenPointPrizeDraw){
          if(value!=parseInt(value)||value < 1 || value > 9999){
            callback(new Error('积分请设置在1~9999之间，整数'))
          }else{
            callback()
          }
        }else{
          callback()
        }
      }
      var checkEveryDayPointPrizeDrawCount = (rule, value, callback) => {
        if(this.ruleForm.IsOpenPointPrizeDraw){
          if(value!=parseInt(value)||value < 1 || value > 9999){
            callback(new Error('次数请设置在1~9999之间，整数'))
          }else{
            callback()
          }
        }else{
          callback()
        }
      }
      var checkPayMoney = (rule, value, callback) => {
        let rules = rule.fullField.split('.')
        let check = this.ruleForm.PayConfigList[rules[1]].check
        if(check){
          if(value<=0||value>99999||!this.two.test(value)){
            callback(new Error('金额请输入0.01~99999元之间，2位小数'))
          }
        }
        callback()
      }
      var checkPrizeDrawCount = (rule, value, callback) => {
        let rules = rule.fullField.split('.')
        let check = this.ruleForm.PayConfigList[rules[1]].check
        if(check){
          if(value<1||value>9999){
            callback(new Error('次数请输入1~9999元之间，整数'))
          }
        }
        callback()
      }
      return{
        imgApi: config.UPLOAD_IMG,
        currentEditType: 0 ,
        currentEditId:0,
        currentEditStatus: 0 ,
				imgUrl: config.IMG_BASE,
        loading:false,
        ruleForm:{
          Name:'',
          StartTime:'',
          EndTime:'',
          ActivityDescription:'',
          NoWinningTip:'',
          NoWinningImgUrl :'/image/20200526-3e3c4652-17de-4c88-a3a5-fa50f8416715.png',
          PageBackgroundImgUrl :'/image/20200519-3955c26c-3a10-448a-b2b7-539588f111ef.png',
          MainColor:'#fc2c48',
          IsDisplayWinners :true,
          PrizeList:[], 
          EmployeeQRCodeImgUrl:'',
          IsOpenFreePrizeDraw :false,
          EveryDayFreePrizeDrawCount:'',
          IsOpenPointPrizeDraw:false,
          PointPrizeDrawUsePointCount:'',
          EveryDayPointPrizeDrawCount:'',
          WheelSurfStyleType:0,
          PayConfigList:[],
        },
        rules:{
          Name:[{
            required: true,
						trigger: 'blur',
						validator: checkName
					}],
					StartTime: [{
						trigger: 'blur',
						validator: checkStartTime
					}],
					EndTime: [{
						trigger: 'blur',
						validator: checkEndTime
          }],
          EmployeeQRCodeImgUrl:[
            { required: true, message: '请上传员工二维码', trigger: 'blur' },
          ],
          EveryDayFreePrizeDrawCount:[{
            trigger: 'blur',
						validator: checkEveryDayFreePrizeDrawCount
          }],
          PointPrizeDrawUsePointCount:[{
            trigger: 'blur',
						validator: checkPointPrizeDrawUsePointCount
          }],
          EveryDayPointPrizeDrawCount:[{
            trigger: 'blur',
						validator: checkEveryDayPointPrizeDrawCount
          }],
          PayMoney:[{
            trigger: 'blur',
						validator: checkPayMoney
          }],
          PrizeDrawCount:[{
            trigger: 'blur',
						validator: checkPrizeDrawCount
          }]
        },
        // 添加奖项
        prizeEditIndex:0,
        editPrizeDia:false,
        selectProShow: false,
        proCheckedId:0,
        selectCouponShow:false,
        couponCheckedId:0,
        prizeForm:{
          PrizeType:1,
          PrizeName:'',
          PrizeImgUri:'/image/20200526-08190968-4da8-4baf-aff3-1a8cae665a6c.png',
          AllCount:0,
          PrizeOdds:'',
          PointsCount :'',
          Coupon :[],
          Product:[],
          SendCount:0,
          NotSendCount:0,
          MaxCashRedEnvelope:'',
          MinCashRedEnvelope:'',
          MaxOrderDiscount:'',
          MinOrderDiscount:'',
        },
        prizeRules:{
          PrizeName : [{
						required: true,
						trigger: 'blur',
						validator: checkPrizeName
          }],
          AllCount: [{
						required: true,
						trigger: 'blur',
						validator: checkAllCount
          }],
          PrizeOdds: [{
						required: true,
						trigger: 'blur',
						validator: checkPrizeOdds
          }],
					PointNum: [{
						trigger: 'blur',
						validator: checkPointNum
					}],
					Coupon: [{
						trigger: 'blur',
						validator: checkCoupon
					}],
					Product: [{
						trigger: 'blur',
						validator: checkProduct
					}],
          MaxCashRedEnvelope: [{
						trigger: 'blur',
						validator: checkMaxCashRedEnvelope
					}],
          MaxOrderDiscount: [{
						trigger: 'blur',
						validator: checkMaxOrderDiscount
					}],
					// nocheck: [{
					// 	trigger: 'blur',
					// 	validator: checkNo
					// }],
        },
        showtype:{
          issuccess:false,
					id:0,
					type:0,
					suctype:8,
        },
        two:/^\d+(\.\d{1,2})?$/,
        maxNum:3
      }
    },
		computed: { 
      defaultContent:function(){
        let content = '请输入活动规则，最多500字；若不填，则使用默认文案：\r\n \r\n1.活动时间\r\n2.请在活动时间结束之内参与抽奖，活动结束后，所有未抽奖次数将失效'
        return content
      },
			minOptions: function() {
				let limitTime = this.ruleForm.StartTime
				let d = new Date();
				let day = d.getDate();
				let month = d.getMonth() + 1;
				let year = d.getFullYear();
				let currentDate = year + '-' + month + '-' + day + ' ' + '00:00:00'
				return {
					disabledDate(time) {
						if (limitTime) {
							return time < new Date(new Date(limitTime).toLocaleDateString())
						} else {
							return time < new Date(currentDate)
						}
					},
				}
			},
			//日期选择器限制选择
			maxOptions: function() {
				let limitTime = this.ruleForm.EndTime
				let d = new Date();
				let day = d.getDate();
				let month = d.getMonth() + 1;
				let year = d.getFullYear();
				let currentDate = year + '-' + month + '-' + day + ' ' + '00:00:00'
				return {
					disabledDate(time) {
						if (limitTime) {
							return (
								time > new Date(new Date(limitTime).toLocaleDateString()) ||
								time < new Date(currentDate)
							)
						} else {
							return time < new Date(currentDate)
						}
					}
				}
			}
    },
    
		created() {
      this.currentEditType = this.$route.query.type
      this.currentEditId = this.$route.query.id
      this.currentEditStatus = this.$route.query.status
      if(this.currentEditType!=2||this.currentEditType!=3){
        this.currentEditStatus = 1
      }
      console.log(this.currentEditStatus)
      if(this.currentEditId!=0){
        this.getInfo()
      }else{
        this.setpaidDraw([])
      }
    },
    methods: {
      setpaidDraw(list){
        for(let item=0;item<this.maxNum;item++){
          if(list[item]){
            this.ruleForm.PayConfigList[item] = list[item]
          }else{
            this.ruleForm.PayConfigList.push({
              check:false,PayMoney:'',PrizeDrawCount:''
            })
          }
        }
        this.ruleForm.PayConfigList = JSON.parse(JSON.stringify(this.ruleForm.PayConfigList))
      },
      handleAvatarSuccess2(file){
        // console.log(file)
        this.ruleForm.EmployeeQRCodeImgUrl = file[0]
      },
      beforeAvatarUpload(file){
        const size = file.size / 1024 / 1024 < 1;
				const testtype = ['image/bmp', 'image/png', 'image/tif', 'image/gif', 'image/jpeg', 'image/jpg',
					'image/webp'
				]
				if (testtype.indexOf(file.type) == -1) {
					this.$message.error('请选择图片上传');
					return false
				} else if (!size) {
					this.$message.error('图片大小请控制在1M以内');
					return false
				}
      },
      recovery(){
        // if(this.$route.query.type==4){
          this.$router.push({path:this.$route.path,query:{
            type:1,
            id:0,
            status:1
          }})
        // }
        this.showtype.issuccess = false
        this.ruleForm = {
          Name:'',
          StartTime:'',
          EndTime:'',
          ActivityDescription:'',
          NoWinningTip:'',
          NoWinningImgUrl :'/image/20200526-3e3c4652-17de-4c88-a3a5-fa50f8416715.png',
          PageBackgroundImgUrl :'/image/20200519-3955c26c-3a10-448a-b2b7-539588f111ef.png',
          MainColor:'#fc2c48',
          IsDisplayWinners :true,
          PrizeList:[], 
          WheelSurfStyleType:0,
          PayConfigList:[],
        }
        this.setpaidDraw([])
      },
      //设置积分
      changePointNum (e) {
        e.target.value =((e.target.value).match(/^\d*(\.{0})/g)[0]) || null
        this.prizeForm.PointsCount = Number(e.target.value)
			},
      
      //设置发放总量
			changeAllCount(e) {
        e.target.value =((e.target.value).match(/^\d*(\.{0})/g)[0]) || null
        this.prizeForm.AllCount = Number(e.target.value)
			},
      
      //设置中奖率
			changePrizeOdds(e) {
        e.target.value =((e.target.value).match(/^\d*(\.?\d{0,2})/g)[0]) || null
        this.prizeForm.PrizeOdds = Number(e.target.value)
        
			},
      async getInfo(){
        try{
          this.loading = true
          let result = await apiList.activityWheelSurfInfo({
            Id:this.currentEditId
          })
          if(result.IsSuccess){
            this.ruleForm = result.Result
            this.ruleForm.PrizeList.forEach(t=>{
              t.Coupon = t.Coupon ? [t.Coupon]:[]
              t.Product = t.Product ? [t.Product]:[]
            })
            this.ruleForm.PayConfigList = this.ruleForm.PayConfigList.map(v=>{
              v.check = true
              return v
            })
            this.setpaidDraw(this.ruleForm.PayConfigList)
          }
          if(this.currentEditType==4){
            this.ruleForm.StartTime = ''
            this.ruleForm.EndTime = ''
            this.ruleForm.PrizeList.forEach(t=>{
              t.NotSendCount = t.AllCount
            })
            this.ruleForm.Id = 0
          }
        } catch(e){
          console.log('e',e)
          this.loading = false
        } finally{
          this.loading = false
        }
      },
      handleAvatarSuccess(res, file) {
        const isLt1M = file.size / 1024 < 1024;
				if (!isLt1M) {
					alert('图片大小请控制在1M以内');
					return false;
				}
        this.ruleForm.NoWinningImgUrl  = res[0];
        this.$forceUpdate();
      },
      handleAvatarBgSuccess(res, file) {
        const isLt2M = file.size / 1024 < 2048;
				if (!isLt2M) {
					alert('图片大小请控制在2M以内');
					return false;
				}
        this.ruleForm.PageBackgroundImgUrl   = res[0];
        this.$forceUpdate();
      },
      handleAvatarPrizeSuccess(res, file){
        const isLt1M = file.size / 1024 < 1024;
				if (!isLt1M) {
					alert('图片大小请控制在1M以内');
					return false;
				}
        this.prizeForm.PrizeImgUri  = res[0];
        this.$forceUpdate();

      },
      // 添加奖项
      handleAddPrize(val,index){
        if(index==undefined){
          this.prizeEditIndex = null
          this.prizeForm = {
            PrizeType:1,
            PrizeName:'',
            PrizeImgUri:'/image/20200526-08190968-4da8-4baf-aff3-1a8cae665a6c.png',
            AllCount:'',
            PrizeOdds:'',
            PointsCount :'',
            Coupon :[],
            Product:[],
            NotSendCount:0,
            MaxCashRedEnvelope:'',
            MinCashRedEnvelope:'',
            MaxOrderDiscount:'',
            MinOrderDiscount:'',
          }
        }else{
          this.prizeEditIndex = index
          this.prizeForm = JSON.parse(JSON.stringify(val))
          this.prizeForm.SendCount = Number(val.AllCount)-Number(val.NotSendCount)
        }
        this.editPrizeDia=true
        this.$nextTick(()=>{
          this.$refs['prizeForm'].clearValidate()
        })
      },
      // 删除奖项
      handleDeletePrize(index){
        this.ruleForm.PrizeList.splice(index,1)
      },
      // 确定选择奖项
      saveAddPrize(ruleForm){
        if(this.prizeForm.PrizeType == 2 && !this.prizeForm.Coupon.length){
          this.$message({
            type:'error',
            message:'请选择优惠券',
            showClose:true
          })
          return 
        }
        
        if(this.prizeForm.PrizeType == 3 && !this.prizeForm.Product.length){
          this.$message({
            type:'error',
            message:'请选择赠品',
            showClose:true
          })
          return 
        }

        this.$refs[ruleForm].validate(async (valid) => {
					if (valid) {
            let NotSendCount = this.prizeEditIndex == undefined ?this.prizeForm.AllCount:(Number(this.prizeForm.AllCount)-Number(this.prizeForm.SendCount))
            let PrizeList = {}
            PrizeList = this.prizeForm
            PrizeList.NotSendCount = NotSendCount
            if (this.prizeEditIndex == undefined) {
              this.ruleForm.PrizeList.push(PrizeList)
            } else {
              this.$set(this.ruleForm.PrizeList, this.prizeEditIndex, PrizeList)
            }
            this.editPrizeDia = false
          }
        });
      },
      handleRadioCheck(){
        this.prizeForm.PointsCount = ''
        this.prizeForm.Coupon = []
        this.prizeForm.Product = []
        this.prizeForm.MaxCashRedEnvelope = ''
        this.prizeForm.MinCashRedEnvelope = ''
        this.prizeForm.MaxOrderDiscount = ''
        this.prizeForm.MinOrderDiscount = ''
        let url1 = '/image/20200526-08190968-4da8-4baf-aff3-1a8cae665a6c.png'
        let url2 = '/image/20200526-7d0a00d2-d0e5-4a84-8950-c6cdde101cc2.png'
        let url3 = '/image/20200526-7189e910-7e0d-43b3-817d-99fca87b748b.png'
        let url7 = '/image/editLotteryDetail-envelope2.png'
        let url8 = '/image/editLotteryDetail-discount2.png'
        if(this.prizeForm.PrizeType==1){
          this.prizeForm.PrizeImgUri = url1
        }else if(this.prizeForm.PrizeType==2){
          this.prizeForm.PrizeImgUri = url2
        }else if(this.prizeForm.PrizeType==3){
          this.prizeForm.PrizeImgUri = url3
        }else if(this.prizeForm.PrizeType==7){
          this.prizeForm.PrizeImgUri = url7
        }else if(this.prizeForm.PrizeType==8){
          this.prizeForm.PrizeImgUri = url8
        }
        // this.prizeForm.PrizeImgUri = this.prizeForm.PrizeType==1?url1:(this.prizeForm.PrizeType==2?url2:url3)
        this.prizeForm.Coupon = []
        this.prizeForm.PointsCount = ''
        this.prizeForm.Product = []
        this.$refs['editLotteryPointsCount'].clearValidate()
      },
      //选择优惠券
			handlePrizeCoupons() {
				let id = this.prizeForm.Coupon.length ? this.prizeForm.Coupon[0].ActivityCouponId : 0
				this.couponCheckedId = id
				this.selectCouponShow = true
      },
      //选择商品
			handlePrizePro() {
				let id = this.prizeForm.Product.length ? this.prizeForm.Product[0].ProductSpecId : 0
				this.proCheckedId = id
				this.selectProShow = true
      },
      getSelectCoupons(selection,isChecked){
				selection.CouponCount = ''
				if (isChecked) {
          selection.ActivityCouponId = selection.Id
          selection.Delstatus = false
					this.prizeForm.Coupon = [selection]
				}
				this.selectCouponShow = false
      },
      getSelectPros(selection,isChecked){
				if (isChecked) {
          selection.ProductSpecId = selection.Id
          selection.ProductName = selection.Name
          selection.Delstatus = false
          selection.IsOpen =  selection.IsOpen
          selection.Price =  selection.ProductPrice
					this.prizeForm.Product = [selection]
				}
				this.selectProShow = false
      },
      // 取消保存
      handleCancelSubmit(){
				this.$router.push({
					path: '/market/lottery'
        });
      },
      // 保存
			handleSubmit(ruleForm) {
				this.$refs[ruleForm].validate(async (valid) => {
					if (valid) {
            if(!this.ruleForm.PrizeList.length){
						  this.$common.completeFormInformation('请完善活动信息','is-error-button')
              return 
            }
            this.saveSubmit()
          }else{
						this.$common.completeFormInformation('请完善活动信息','is-error')
						console.log('error submit!!');
						return false;
          }
				});
      },
      async saveSubmit(){

        try{
          this.loading=true

          let PrizeList = []
          
          this.ruleForm.PrizeList.forEach(t=>{
            let item = {
                ActivityCouponId:t.Coupon.length?t.Coupon[0].ActivityCouponId:null ,
                AllCount: t.AllCount,
                Id: t.Id,
                PointsCount: t.PointsCount,
                PrizeImgUri: t.PrizeImgUri,
                PrizeName: t.PrizeName,
                PrizeType: t.PrizeType,
                ProductSpecId: t.Product.length?t.Product[0].ProductSpecId:null,
                PrizeOdds:t.PrizeOdds,
                MinCashRedEnvelope:t.PrizeType==7?t.MinCashRedEnvelope:null,
                MaxCashRedEnvelope:t.PrizeType==7?t.MaxCashRedEnvelope:null,
                MinOrderDiscount:t.PrizeType==8?t.MinOrderDiscount:null,
                MaxOrderDiscount:t.PrizeType==8?t.MaxOrderDiscount:null,
            }
            PrizeList.push(item)
          })
          this.ruleForm.PayConfigList = this.ruleForm.PayConfigList.filter(v=>v.check)
          let data= {
            Id: this.currentEditType==4?null:this.currentEditId,
            Name: this.ruleForm.Name,
            StartTime: this.ruleForm.StartTime,
            EndTime: this.ruleForm.EndTime,
            ActivityDescription: this.ruleForm.ActivityDescription,
            PrizeList: PrizeList,
            NoWinningTip: this.ruleForm.NoWinningTip,
            NoWinningImgUrl: this.ruleForm.NoWinningImgUrl,
            PageBackgroundImgUrl: this.ruleForm.PageBackgroundImgUrl,
            MainColor: this.ruleForm.MainColor,
            IsDisplayWinners: this.ruleForm.IsDisplayWinners,
            EmployeeQRCodeImgUrl:PrizeList.some(v=>v.PrizeType==7||v.PrizeType==8)?this.ruleForm.EmployeeQRCodeImgUrl:'',
            IsOpenFreePrizeDraw:this.ruleForm.IsOpenFreePrizeDraw,
            EveryDayFreePrizeDrawCount:this.ruleForm.IsOpenFreePrizeDraw?this.ruleForm.EveryDayFreePrizeDrawCount:'',
            IsOpenPointPrizeDraw:this.ruleForm.IsOpenPointPrizeDraw,
            PointPrizeDrawUsePointCount:this.ruleForm.IsOpenPointPrizeDraw?this.ruleForm.PointPrizeDrawUsePointCount:'',
            EveryDayPointPrizeDrawCount:this.ruleForm.IsOpenPointPrizeDraw?this.ruleForm.EveryDayPointPrizeDrawCount:'',
            IsRestart:this.currentEditType==5,
            PayConfigList:this.ruleForm.PayConfigList,
            WheelSurfStyleType:this.ruleForm.WheelSurfStyleType 
          }
          let result = await apiList.activityWheelSurfSave(data)
          if(result.IsSuccess){
            this.$message({
              type:'success',
              message:'保存成功',
              showClose:true
            })
            if(this.$route.query.id==0||this.$route.query.type==4){
              this.showtype = {
                issuccess:true,
                id:result.Result.Id,
                suctype:8,
              }
            }else{
              this.$router.push({
                path: '/market/lottery'
              });
            }
          }

        }catch(e){
          console.log("e",e)
          this.loading=false
        } finally{
          this.loading=false

        }
      }
    }
  }
</script>
<style lang="less" scoped>
.blur-border{
  background: #FBFDFF;
  border: 1px solid #409EFF;
  border-radius: 0px 0px 0px 0px;
  padding: 10px 15px;
  font-size: 14px;
  color: #999999;
  line-height: 20px;
}
.flexRow{
  display: flex;
  align-items: center;
}
.grayfont{
  font-size: 14px;
  color: #999999;
  line-height: 1.5;

}
.flexCol{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  
}
.inline-error ::v-deep .el-form-item__error{
  position: absolute !important;;
    top: 25%;
    left: 315px;
    width: 100%;
}
  .upload-avatar {
    width: 145px;
    height: 145px;
    object-fit: contain;
  }
  ::v-deep .el-color-picker--medium .el-color-picker__trigger {
    width: 145px !important;
  }
  .footer {
		box-shadow: 13px 1px 6px #999;
		line-height: 80px;
		background-color: #fff;
		position: fixed;
		bottom: 0px;
    width: 100%;
    z-index :9;
		text-align: center;
  }
  .dialog-footer {
		text-align: center;

		.button {
			width: 150px;
		}
	}

	.el-form-date-pick {
		display: inline-block;
  }
  .radio-group {

		.el-form-date-pick {
			display: inline-block;
		}
  }
  .dialog-name-content{
    width:200px;
    overflow:hidden;
    display:-webkit-box;
    text-overflow:ellipsis;
    -webkit-line-clamp:2;
		-webkit-box-orient:vertical;
  }
  .dialog-pro-name {
		display: flex;
		flex-direction: row;

		img {
			width: 60px;
			height: 60px;
			object-fit: contain;
			margin-right: 10px;
		}

		span {
			color: #E51C23;
			font-size: 12px;
		}
  }
  
	::v-deepinput[type=number]::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}

	::v-deep .number__input {
		border: 1px solid #ccc;
	}
</style>