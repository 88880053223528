<template>
  <div class="contanier">
    <div class="filter-container title-border">
      <!-- 关键字搜索 -->
      <div class="filter-item">
        <label class="label">关键字: </label>
        <el-input v-model="searchKey" style="width:200px;" placeholder="优惠券名称"></el-input>
      </div>
      <!--销售类型  -->
      <div class="filter-item">
        <label class="label">优惠券类型: </label>
        <el-select v-model="type" placeholder="请选择">
          <el-option label="全部" :value="null"></el-option>
          <el-option
            v-for="item in typeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div>
      <!-- button -->
      <div class="filter-item">
        <el-button type="primary" style="margin:0 30px;width:90px;" @click="handleFilter">查询</el-button>
      </div>
    </div>
    <!-- table -->
    <div class="table-container content">
      <el-table	:data="tableDataList" height='450px'	style="width: 100%" ref="calendarCouponSelectTable" empty-text="查询不到相关优惠券">
         <!-- :selectable='selectInit' -->
        <el-table-column  width="55">
          <template scope="scope">
            <el-radio :label="scope.row.Id" v-model="templateRadio" :disabled="scope.row.Remark" @change.native="getTemplateRow(scope.row)">{{&nbsp;}}</el-radio>
          </template>
        </el-table-column>
        <el-table-column	prop="CouponName"	label="优惠券"></el-table-column>
        <el-table-column	prop="UseTypeValue"	label="类型"></el-table-column>
        <el-table-column	prop="CouponContent"	label="优惠内容"></el-table-column>
        <el-table-column	prop="ActivityRangeTypeValue"	label="适用商品"></el-table-column>
        <el-table-column	prop="ReceiveLimitValue"	label="限领次数"></el-table-column>
        <el-table-column	prop="LeaveCount"	label="剩余数量"></el-table-column>
      </el-table>
      <el-pagination v-if="page.total" style="margin-top:20px;float:right;" @size-change="handleSizeChange"
			 @current-change="handleCurrentChange" :current-page="page.current" :page-sizes="[5,10, 15, 20, 25, 30]" :page-size="page.size"
			 layout="total, sizes, prev, pager, next, jumper" :total="page.total">
			</el-pagination>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button class="button" @click="saveSelectProList" style="margin-left:10px;" type="primary">确定</el-button>
    </div>
  </div>
</template>
<script>
import apiList from '@/api/other'
export default {
  name: 'selectCoupon',
  props:{
    couponCheckedId: {
      type:Number,
    }
  },
  data(){
    return {
      searchKey:'',
      type:null,
      // typeOptions:[
      //   {
      //     value:'满减券',
      //     label:0
      //   },
      //   {
      //     value:'折扣券',
      //     label:1
      //   },
      //   {
      //     value:'随机金额',
      //     label:2
      //   }
      // ],
      typeOptions: [{
						value: 0,
						label: '满减券'
					},
					{
						value: 1,
						label: '折扣券'
					},
					{
						value: 2,
						label: '随机金额券'
					}
				],
				page:{
					total:0,
					current:1,
					size:10
        },
        tableDataList:[],
        checkedAll:false,
        multipleSelection:[],
        templateRadio:'',
        selectCoupon:{},
        isChecked:false
    }
  },
  created(){
      this.getDataList()
  },
  methods:{
    async getDataList() {
      this.loading = true;
      try {
        let data = {
          Keyword : this.searchKey,
          UseType : this.type,
          Skip: (this.page.current - 1) * this.page.size, // 略过数据
          Take: this.page.size, // 取的数据
          IsUse:true
        };
        let result = await apiList.activitycouponpopupList(data);
        this.templateRadio=this.couponCheckedId
        this.tableDataList =result.Result.Results
        this.page.total = result.Result.Total;
        
      } catch (error) {
        console.log(error);
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    handleFilter(){
      this.getDataList()
    },
    getTemplateRow(row){
      this.isChecked = true
      this.selectCoupon = row
    },
    saveSelectProList(){
      if(this.selectCoupon.length==0){
        this.$message({
          type:'error',
          message:'请选择优惠券'
        })
      }else{
        this.$emit('getSelectCoupon',this.selectCoupon,this.isChecked)
      }
    },
    handleSizeChange(val){
      this.page.size=val
      this.getDataList()
    },
    handleCurrentChange(val){
      this.page.current=val
      this.getDataList()
    }
      
  }
}
</script>
<style lang="less" scoped>
.content{
    overflow: hidden;
  }
  .dialog-footer{
    .button{
      width: 150px;
    }
    text-align: center;
    margin-top: 20px;
  }
</style>